.root {
    background: linear-gradient(-45deg, #ee7752, #be2560, #23a6d5, #2326d5);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;   
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.listContainer {
    width: 100%;
    height: 100%;
    margin-top: 5%;
}

.gridContainer {
    display: flex;
    padding: 0px;
    margin: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    overflow-x: scroll;
}

.profileItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 25%;
    height: 10%;
    margin: 0;
}

.profileItem:hover  {
    color: black;
}

button {
    background-color: transparent;
    border-color: transparent;
}

button:hover {
    cursor: pointer;
}

button:hover h1 {
    color: #ffffff !important;
}