.artist-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20ex;
}

.artist-container h1 {
    font-size: 6ex;
    padding-left: 25px;
}

a {
    color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
}

a:hover h1 {
    color: #1DB954;
}

ul {
    color: white;
    overflow-y: scroll;
    height: 100vh;
}