.profile {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profilePics img {
    margin: 10px;
}
.artistsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 75%;
}
.artistsTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.artistsContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}