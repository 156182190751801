.track-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20ex;
}

.track-container h1 {
    font-size: 2.75ex;
    padding-left: 25px;;
}

a {
    color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;
}

a:hover h1 {
    color: #1DB954;
}