.profileContainer {
    display: flex;
    flex-direction: row;
    z-index: 1; 
    background-color: transparent;
}

.profileContainer img {
    width: 8ex;
    height: 8ex;
    border-radius: 4ex;
    pointer-events: none;
}

.profileContainer h1 {
    font-size: 4ex;
    pointer-events: none;
}