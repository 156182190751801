.body {
    overflow-x: scroll;
}
.root {
    display: flex;
    overflow-x: scroll;
    background: linear-gradient(-45deg, #ee7752, #be2560, #23a6d5, #2326d5);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 25px;
    margin: 0
}
.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nowPlaying {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.tracksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    width: 75%;
}
.tracksTitle {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.volumeIcon {
    animation: resize;
}

.tracksContent {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tracksTitle h1 {
    font-size: 10ex;
}

.artistsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 75%;
}
.artistsTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 50%;
}

.artistsContent {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.artistsTitle h1 {
    font-size: 10ex;
    padding-left: 50px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px;
}

.directoryButton {
    width: 150px;
    height: 30px;
    background-color: #1DB954;
    border-color: #1DB954;
    border-radius: 4px;
    font-weight: bolder;
    color: white;
}

.directoryButton:focus {
    outline: none;
}

.profile {
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

h1 {
    color:#F8F8FF;
}

ul {
    color: #F8F8FF;;
    overflow-y: scroll;
    display: inline-block;
}