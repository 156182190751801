.LoginPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: linear-gradient(-45deg, #ee7752, #be2560, #23a6d5, #2326d5);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;    
    text-align: center;
    justify-content: center;
    align-items: center;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.description {
    color: white;
}

.header {
    color: white;
    font-size: 5.5vw;
    margin: 0;
}

.loginButton {
    background-color: transparent;
    width: 12vh;
    height: 5vh;
    font-size: large;
    border-radius: 20pc;
    border-color: black;
}

.loginButton:hover {
    background-color: white;
}

.loginButton:focus {
    outline: none;
}